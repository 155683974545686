export const navigation = [
  {
    text: 'Главная',
    path: '/home',
    icon: 'info'
  }, 
  {
    text: 'Карта',
    path: '/map',
    icon: 'map'
  }, 
  {
    text: 'Дома',
    path: '/house',
    icon: 'home'
  }, 
  {
    text: 'Школы',
    path: '/school',
    icon: 'formula'
  }, 
  {
    text: 'Спорт',
    path: '/sport',
    icon: 'runner'
  }, 
  {
    text: 'Парковки',
    path: '/parking',
    icon: 'car'
  }, 
  {
    text: 'Магазины',
    path: '/shop',
    icon: 'cart'
  }, 
  {
    text: 'Кафе и рестораны',
    path: '/cafe-list',
    icon: 'food'
  }, 
  {
    text: 'Hospital List',
    path: '/hospital-list',
    icon: 'add'
  }, 
  {
    text: 'Bank List',
    path: '/bank-list',
    icon: 'money'
  }
 ];
