import React, { useCallback } from 'react';
import './SearchAddress.scss';
import { Autocomplete } from 'devextreme-react';
import Button from 'devextreme-react/button';
import CustomStore from 'devextreme/data/custom_store';
import { useNavigate } from "react-router-dom";

//const url = 'https://localhost:5001/v1';
const url = 'https://api.domoindex.ru/v1';

const houseOData = {
  store: {
    type: 'odata',
    url: `${url}/house`,
    key: 'id',
    version: 4
  }
  //, filter: ['Id', '>', 0]
};

export default (props) => {
  const navigate = useNavigate();
  const search = useCallback(() => {
    console.log("search");
  }, []);

  // function onValueChanged(e) {
  //     console.log("onValueChanged-" + e.value);
  // }

  function onSelectionChanged(e) {
    var houseId = e.selectedItem.id;
    //console.log("onSelectionChanged-" + houseId);
    if (houseId != null) {
      navigate("/house/"+ houseId);
    }
  }

  return (
    <React.Fragment>
      <div className={'search-panel'}>
        <div className={'search-autocomplete'}>
          <Autocomplete
            dataSource={houseOData}
            valueExpr="name"
            label="Введите адрес"
            labelMode="floating"
            showClearButton={true}
            // onValueChanged={onValueChanged}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
        <div className={'search-button'}>
          <Button icon="search" onClick={search} />
        </div>
      </div>
    </React.Fragment>
  );
};