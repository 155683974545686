import React, {useRef, useEffect } from 'react';
import ClustererCreate from "./clustererCreate";
import useContainerDimensions from "../../hooks/useContainerDimensions";

import './map.scss';

export default () => {

  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);

  return (
    <React.Fragment>
       <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'} ref={componentRef}>
          <ClustererCreate width={width} height={height}/>
        </div>
      </div>
    </React.Fragment>
  );
}