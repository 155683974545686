import React, { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './school.scss';
import { Button } from 'devextreme-react/button';

export default (props) => {
  const navigate = useNavigate();

  let { id } = useParams()
  return (
    <React.Fragment>
      <h2 className={'content-block'}>
        <ul class="horizontal">
          <li>
            <Button className={'back-button'} icon="back" id="iconk" onClick={() => navigate.goBack()} />
          </li>
          <li>Школа {id}</li>
        </ul>
      </h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          Put your content here
        </div>
      </div>
    </React.Fragment>
  );
};
