import React from 'react';
import './school-list.scss';

export default () => (
  <React.Fragment>
    <h2 className={'content-block'}>School List</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
       Список школ
      </div>
    </div>
  </React.Fragment>
);
