import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import CustomStore from 'devextreme/data/custom_store';
import { Item, Box } from 'devextreme-react/box';
import { YMaps, Map, Clusterer, Placemark } from "@pbe/react-yandex-maps";
import './house.scss';
import useContainerDimensions from "../../hooks/useContainerDimensions";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Editing,
  HeaderFilter,
  SearchPanel,
  MasterDetail
} from 'devextreme-react/data-grid';

//const url = 'https://localhost:5001/v1';
const url = 'https://api.domoindex.ru/v1';

const networkError = 'Network error';

export default (props) => {
  const navigate = useNavigate();
  let { id } = useParams()
  const [house, setHouse] = useState()

  const houseStore = {
    store: new CustomStore({
      key: "id",
      loadMode: "raw",
      load: () => {
        return fetch(`${url}/house`)
          .then(response => response.json())
          .catch(() => { throw networkError });
      },
      byKey: (key) => {
        return fetch(`${url}/house/${encodeURIComponent(key)}`)
          .then(response => response.json())
          .catch(() => { throw networkError });
      },
    })
  };

  const houseOData = {
    store: {
      type: 'odata',
      url: `${url}/house`,
      key: 'id',
      version: 4
    }
    //, filter: ['Id', '>', 0]
  };

  const handleClick = (e) => {
    navigate(-1);
    /* Не работает
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      // the current entry in the history stack will be replaced with the new one with { replace: true }
      navigate('/', { replace: true });
    }*/
  }

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, []);
  
    

  // var lat = 55.751574;
  // var lon = 80.573856;
  var lat = house?.geoY || 55.751574;
  var lon = house?.geoX || 80.573856;

  const mapState = {
    center: [lat, lon],
    zoom: 14,
    behaviors: ["default", "scrollZoom"]
  };

  useEffect(() => {
    houseStore.store.byKey(id)
    .done(function(result) {
      setHouse(result);
    })
    .fail(function(error) {
        //error callback
    });
  }, []);

  return (
    <React.Fragment>
      <h2 className={'content-block'}>
        <ul className="horizontal">
          <li>
            <Button className={'back-button'} icon="back" id="iconback" onClick={handleClick} />
          </li>
          <li>{house?.name}</li>
        </ul>
      </h2>
      <Box className={'content-block'}
        direction="row"
        width="100%"
        height="100%">
        <Item ratio={2} width="100%">
          <YMaps width="100%">
            <Map
              state={mapState}
              width="100%" height={600}
              modules={["package.full"]}>
              <Placemark
                geometry={[lat, lon]}>
              </Placemark>
            </Map>
          </YMaps>
        </Item>
        <Item ratio={1}>
          <div className="dx-fieldset" id="house-fieldset">
            {/* <div className="dx-fieldset-header" id="house-header">{house?.name}</div> */}
            <div className="dx-field" id="house-label">
              <div className="dx-field-label" id="house-label">Округ</div>
              <div className="dx-field-value-static" id="house-label">{house?.regionL1Text || '-'}</div>
            </div>
            <div className="dx-field" id="house-label">
              <div className="dx-field-label" id="house-label">Район</div>
              <div className="dx-field-value-static" id="house-label">{house?.regionL2Text || '-'}</div>
            </div>
            <div className="dx-field" id="house-label">
              <div className="dx-field-label" id="house-label">Кол-во квартир</div>
              <div className="dx-field-value-static" id="house-label">{house?.capacity || '-'}</div>
            </div>
            <div className="dx-field" id="house-label">
              <div className="dx-field-label" id="house-label">Площадь, м2</div>
              <div className="dx-field-value-static" id="house-label">{house?.totalArea || '-'}</div>
            </div>
            <div className="dx-field" id="house-label">
              <div className="dx-field-label" id="house-label">Год постройки</div>
              <div className="dx-field-value-static" id="house-label">{house?.startYear || '-'}</div>
            </div>
          </div>
        </Item>
      </Box>
      <h2 className={'content-block'}>Перечень школ</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
        <DataGrid
          dataSource={houseOData}
          showBorders={true}>
          <Paging enabled={true} defaultPageSize={10} />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} />
          {/* <MasterDetail
            enabled={true}
            component={MasterDetailView}
          /> */}
          <Column dataField={'id'}
            alignment={'left'} 
            dataType="number"
            width={75} 
            />
            {/* <Column
            dataField={'city'}
            caption={'Город'}
            hidingPriority={2}
          /> */}
           <Column
            dataField={'regionL1Text'}
            caption={'Округ'}
            hidingPriority={15}
          />
           <Column
            dataField={'regionL2Text'}
            caption={'Район'}
            hidingPriority={20}
          />
          <Column
            dataField={'name'}
            caption={'Адрес'}
            minWidth={400}
          />
          <Column
            dataField={'roomsCount'}
            caption={'Квартир'}
            alignment={'left'}
            hidingPriority={10}
            dataType="number" />
            <Column
            dataField={'totalArea'}
            caption={'Площадь, м2'}
            alignment={'left'}
            hidingPriority={5}
            dataType="number" />
          <Column
            dataField={'startYear'}
            caption={'Год постройки'}
            alignment={'left'}
            hidingPriority={1}
            dataType="number" />
        </DataGrid>
      </div>
    </div>
    </React.Fragment>
  );
};
