export const gradientColors = [
  "#ff0000",
  "#f66d00",
  "#d7a700",
  "#a0d600",
  "#00ff00"
];

export const points = [
  [52.567157, 39.507682],
  [53.746521, 91.416848],
  [51.638942, 46.017467],
  [53.640536, 55.90163],
  [52.345387, 104.147889],
  [57.026488, 40.968522],
  [47.205741, 39.726862],
  [57.643152, 39.919972],
  [58.577091, 49.589528],
  [55.572095, 37.665255],
  [52.310697, 104.293991],
  [56.239868, 44.00048],
  [59.115019, 37.949185],
  [54.810009, 56.080862],
  [59.829889, 30.340132],
  [45.005539, 39.104922],
  [50.57419, 36.543088],
  [56.897733, 53.311347],
  [55.812757, 37.058703],
  [43.466215, 39.897541],
  [52.028255, 113.532302],
  [54.756428, 20.563443],
  [56.29022, 101.704166],
  [56.130106, 47.294934],
  [43.166623, 131.903496],
  [47.273491, 39.852392],
  [43.519979, 43.615731],
  [54.233922, 45.151626],
  [53.570867, 49.286823],
  [47.500799, 42.160434],
  [55.123147, 61.390795],
  [51.690147, 39.126392],
  [55.863257, 37.344772],
  [53.235861, 34.327825],
  [55.106855, 82.985845],
  [61.652773, 50.838599],
  [55.889871, 37.6342],
  [58.037036, 56.192235],
  [48.75408, 44.472077],
  [53.163816, 44.987602],
  [53.248012, 83.692047],
  [53.02674, 158.672797],
  [55.315699, 86.145974],
  [50.285593, 127.536004],
  [59.901739, 30.291137],
  [57.969374, 56.178895],
  [56.105279, 40.345127],
  [55.8169, 37.78288],
  [57.187612, 65.56337],
  [54.294207, 48.276218],
  [59.86378, 30.258834],
  [55.69521, 52.364406],
  [51.780966, 55.173052],
  [59.264102, 39.900371],
  [52.946529, 36.025749],
  [43.028189, 44.667685],
  [47.283218, 39.843634],
  [53.136258, 50.178032],
  [54.638549, 39.731254],
  [44.745701, 37.725128],
  [44.989145, 41.930824],
  [60.967272, 76.538654],
  [51.51479, 45.988164],
  [56.498927, 84.979341],
  [56.752289, 60.602289],
  [54.946695, 73.348287],
  [50.618766, 36.552539],
  [55.65886, 37.895242],
  [53.421525, 58.95981],
  [61.256605, 73.479271],
  [56.258268, 43.904441],
  [44.044351, 42.999631],
  [56.608843, 47.886789],
  [56.291284, 43.882756],
  [55.641859, 37.64162],
  [56.223211, 43.851449],
  [56.296309, 43.983016],
  [56.278597, 43.90003],
  [59.889367, 30.363209],
  [59.906206, 30.452996],
  [59.989166, 30.324195],
  [60.005855, 30.438156],
  [48.481722, 135.126912],
  [47.059405, 142.727191],
  [56.025633, 92.808213],
  [53.123993, 46.541948],
  [56.049661, 92.834048],
  [51.673589, 39.256576],
  [57.105324, 65.651064],
  [55.782785, 49.187604],
  [56.311227, 43.847101],
  [42.903617, 47.514463],
  [46.319931, 48.053984],
  [53.306379, 34.268734],
  [55.491518, 37.568237],
  [55.890073, 37.489647],
  [52.726837, 32.254145],
  [54.207769, 37.577175],
  [56.946378, 40.967399],
  [48.782915, 44.584744],
  [52.74953, 41.459029],
  [51.848308, 107.566877],
  [55.197168, 61.445008],
  [45.27737, 38.217333],
  [45.066609, 38.98559],
  [55.784374, 37.868903],
  [56.885333, 60.533299],
  [43.332545, 45.710701],
  [57.805677, 28.422157],
  [62.039823, 129.62845],
  [56.899539, 60.76673],
  [55.835791, 38.97136],
  [57.557502, 39.821535],
  [68.925567, 33.105775],
  [58.5313, 31.259243],
  [55.617154, 37.779278],
  [54.542018, 36.304047],
  [53.331134, 83.665564],
  [55.840962, 49.106207],
  [55.879445, 37.548555],
  [51.508741, 46.205017],
  [45.124433, 39.005011],
  [44.207345, 43.091843],
  [54.835248, 32.047407],
  [43.181882, 131.921193],
  [54.899514, 52.332318],
  [53.744077, 87.116308],
  [56.813735, 35.983386],
  [51.777488, 39.196903],
  [55.84027, 49.198384],
  [54.161266, 37.636428],
  [54.691303, 55.796931],
  [55.726875, 37.380594],
  [55.130636, 61.473545],
  [43.064556, 44.628922],
  [55.772899, 37.536909]
];
