import React from 'react';
import './house-list.scss';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Editing,
  HeaderFilter,
  SearchPanel,
  MasterDetail
} from 'devextreme-react/data-grid';
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";
//import MasterDetailView from './MasterDetailView.js'; 
import { useHistory } from 'react-router-dom';
import { Redirect, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

//const url = 'https://localhost:5001/v1';
const url = 'https://api.domoindex.ru/v1';

const houseOData = {
  store: {
    type: 'odata',
    url: `${url}/house`,
    key: 'id',
    version: 4
  }
  //, filter: ['Id', '>', 0]
};

function cellRenderHouse(data) {
  return <div className="house-link">
    <Link to={'/house/' + data.data.id}>{data.value}</Link>
  </div>;
}

function cellRenderRegionL1(data) {
  return <div className="regionL1-link">
    <Link to={'/region-l1/' + data.data.regionL1Id}>{data.value}</Link>
  </div>;
}

function cellRenderRegionL2(data) {
  return <div className="regionL2-link">
    <Link to={'/region-l2/' + data.data.regionL2Id}>{data.value}</Link>
  </div>;
}

export default () => (
  <React.Fragment>
    <h2 className={'content-block'}>Перечень домов</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
        <DataGrid
          dataSource={houseOData}
          showBorders={true}>
          <Paging enabled={true} defaultPageSize={10} />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} />
          {/* <MasterDetail
            enabled={true}
            component={MasterDetailView}
          /> */}
          <Column dataField={'id'}
            alignment={'left'} 
            dataType="number"
            width={75} 
            />
            {/* <Column
            dataField={'city'}
            caption={'Город'}
            hidingPriority={2}
          /> */}
           <Column
            dataField={'regionL1Text'}
            caption={'Округ'}
            cellRender={cellRenderRegionL1}
            hidingPriority={15}
          />
           <Column
            dataField={'regionL2Text'}
            caption={'Район'}
            cellRender={cellRenderRegionL2}
            hidingPriority={20}
          />
          <Column
            dataField={'name'}
            caption={'Адрес'}
            cellRender={cellRenderHouse}
            minWidth={400}
          />
          <Column
            dataField={'roomsCount'}
            caption={'Квартир'}
            alignment={'left'}
            hidingPriority={10}
            dataType="number" />
            <Column
            dataField={'totalArea'}
            caption={'Площадь, м2'}
            alignment={'left'}
            hidingPriority={5}
            dataType="number" />
          <Column
            dataField={'startYear'}
            caption={'Год постройки'}
            alignment={'left'}
            hidingPriority={1}
            dataType="number" />
        </DataGrid>
      </div>
    </div>
  </React.Fragment>
);
