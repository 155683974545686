import { HomePage, TasksPage, ProfilePage, MapPage, HouseListPage, SchoolListPage, SportListPage, ParkingListPage, ShopListPage, HousePage, SchoolPage, SportPage, ParkingPage, ShopPage, RegionL1Page, RegionL2Page, RegionL2ListPage, RegionL1ListPage, CafeListPage, CafePage, HospitalListPage, HospitalPage, BankPage, BankListPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
  {
    path: '/home',
    element: HomePage
  },
  {
    path: '/map',
    element: MapPage
  },
  {
    path: '/house',
    element: HouseListPage
  },
  {
    path: '/school',
    element: SchoolListPage
  },
  {
    path: '/sport',
    element: SportListPage
  },
  {
    path: '/parking',
    element: ParkingListPage
  },
  {
    path: '/shop',
    element: ShopListPage
  },
  {
    path: '/house/:id',
    element: HousePage
  },
  {
    path: '/school/:id',
    element: SchoolPage
  },
  {
    path: '/sport/:id',
    element: SportPage
  },
  {
    path: '/parking/:id',
    element: ParkingPage
  },
  {
    path: '/shop/:id',
    element: ShopPage
  },
  {
    path: '/region-l1/:id',
    element: RegionL1Page
  },
  {
    path: '/region-l2/:id',
    element: RegionL2Page
  },
  {
    path: '/region-l2-list',
    element: RegionL2ListPage
  },
  {
    path: '/region-l1-list',
    element: RegionL1ListPage
  },
  {
    path: '/cafe-list',
    element: CafeListPage
  },
  {
    path: '/cafe/:id',
    element: CafePage
  },
  {
    path: '/hospital-list',
    element: HospitalListPage
  },
  {
    path: '/hospital/:id',
    element: HospitalPage
  },
  {
    path: '/bank-list',
    element: BankListPage
  },
  {
    path: '/bank/:id',
    element: BankPage
  }
];

export default routes.map(route => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path)
  };
});
